var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.looktype == 1)?_c('div',{staticClass:"content"},[_c('div',[_c('div',{staticClass:"scrollbar-hidden"},_vm._l((_vm.$store.state.customData.category),function(item,index){return _c('div',{key:index,staticClass:"leimu"},[_vm._l((item),function(its,ind){return _c('span',{key:its.id,on:{"click":function($event){return _vm.search(its)}}},[_vm._v(_vm._s(its.name_as)),(ind != item.length - 1)?_c('span',[_vm._v("/")]):_vm._e()])}),_c('div',{staticClass:"erjidaohang"},_vm._l((item),function(its){return _c('div',{key:its.id},[_c('div',{staticClass:"showTitle",on:{"click":function($event){return _vm.search(its)}}},[_vm._v(" "+_vm._s(its.name_as)+"/"+_vm._s(its.name)+" ")]),_c('div',_vm._l((its.children),function(it){return _c('span',{key:it.id,on:{"click":function($event){return _vm.search(its, it)}}},[_vm._v(_vm._s(it.name))])}),0)])}),0)],2)}),0)]),_c('div',[_c('el-carousel',{attrs:{"height":"528px"}},_vm._l((_vm.$store.state.customData.banner),function(item,index){return _c('el-carousel-item',{key:index},[_c('div',{staticClass:"img-wrap"},[_c('img',{staticClass:"bannersImg",attrs:{"src":item.img_path,"alt":""},on:{"click":function($event){return _vm.gotoPage(item.nav_url)}}})])])}),1)],1),_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.$store.state.customData.promote.welcome)+" ")]),_c('div',_vm._l((_vm.$store.state.customData.promote.button.btn),function(item,index){return _c('div',{key:index,style:(index == 0
            ? 'background-color:' +
              _vm.$store.state.customData.promote.button.bg_color +
              ';border-color:' +
              _vm.$store.state.customData.promote.button.bg_color +
              ';color:#fff'
            : 'border-color:' +
              _vm.$store.state.customData.promote.button.bg_color +
              ';color:' +
              _vm.$store.state.customData.promote.button.bg_color +
              ';'),on:{"click":function($event){return _vm.gotoPage(item.url)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0),_c('div',_vm._l((_vm.$store.state.customData.promote.nav.icon),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.gotoPage(item.url)}}},[((item.code.code || '').startsWith('http'))?_c('img',{attrs:{"src":item.code.code,"alt":"","width":"36px","height":"36px"}}):_c('i',{class:item.code.code,style:(("color:" + (item.code.color) + ";font-size:36px"))}),_c('span',{staticStyle:{"margin-top":"5px"}},[_vm._v(_vm._s(item.title))])])}),0),_c('div',[_c('el-carousel',{attrs:{"height":"144px","indicator-position":"none"}},_vm._l((_vm.$store.state.customData.promote.ad),function(item,index){return _c('el-carousel-item',{key:index},[_c('div',{staticClass:"img-wrap"},[_c('img',{staticClass:"bannersImg",attrs:{"width":"100%","src":item.img_path,"alt":""},on:{"click":function($event){return _vm.gotoPage(item.url)}}})])])}),1)],1)])]):_vm._e(),(_vm.looktype == 2)?_c('div',[_c('div',[_c('div',{staticClass:"mobile-nav"},[_vm._l((_vm.navLen),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.toList(item)}}},[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":item.icon_path && item.icon_path.code}})]),_c('p',{staticClass:"ellipsisText"},[_vm._v(_vm._s(item.name_as))])])}),_c('div',{on:{"click":_vm.open}},[_c('div',{staticClass:"bg-img",style:({background: ("url(" + (_vm.$store.state.customData.logo.logo_path) + ")"), 'background-repeat': 'no-repeat',
    'background-size': 'contain',
    'background-position': 'center'})}),_c('p',[_vm._v("更多分类")])])],2)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }