<template>
  <div>
    <div v-if="$store.state.customData.style == 1">
      <div class="contents">
        <searchHeader />
        <contents v-if="$store.state.customData.tmp_is_enable == 1" :style="{widht: looktype == 1? '100vw': '100%'}"/>
      </div>
      <apaas
        v-for="item in $store.state.customData.apaas_page"
        :key="item.id"
        :page="item.page_id || item.id"
      />
    </div>
    <div v-else>
      <searchModel />
      <contentsModel v-if="$store.state.customData.tmp_is_enable == 1" />
      <apaas
        v-for="item in $store.state.customData.apaas_page"
        :key="item.id"
        :page="item.page_id || item.id"
      />
    </div>
  </div>
</template>

<script>
import contentsModel from "./components/contentsModel.vue";
import searchModel from "./components/searchModel.vue";
import contents from "./components/contents.vue";
import searchHeader from "./components/searchTitles.vue";
import apaas from "./components/apaas.vue";
export default {
  components: {
    contentsModel,
    searchModel,
    searchHeader,
    contents,
    apaas,
  },
  data() {
    return {
      input: "",
      showSearch: false,
    };
  },
  methods: {
    inputText(val) {
      this.input = val.target.value;
      if (!this.input) {
        this.showSearch = false;
      } else {
        this.showSearch = true;
      }
    },
    gotoPage(val) {
      window.open(location.origin + val);
    },
  },
};
</script>

<style lang="scss" scoped>
.contents {
  // width: 1200px;
  margin: 0 auto;
}
/deep/ iframe{
  margin:0 !important;
}
</style>
