<template>
  <div class="content">
    <div>
      <div class="scrollbar-hidden">
        <div
          class="leimu"
          v-for="(item, index) in $store.state.customData.category"
          :key="index"
        >
          <span @click="search(its)" v-for="(its, ind) in item" :key="its.id"
            >{{ its.name_as }}<span v-if="ind != item.length - 1">/</span>
          </span>
          <div class="erjidaohang">
            <div v-for="its in item" :key="its.id">
              <div class="showTitle" @click="search(its)">
                {{ its.name_as }}/{{ its.name }}
              </div>
              <div>
                <span  @click="search(its, it)" v-for="it in its.children" :key="it.id">{{ it.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-carousel height="528px">
        <el-carousel-item
          v-for="(item, index) in $store.state.customData.banner"
          :key="index"
        >
          <div class="img-wrap">
            <img
              @click="gotoPage(item.nav_url)"
              class="bannersImg"
              :src="item.img_path"
              alt=""
            />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    console.log(this.$store.state.customData.category);
  },
  methods: {
    gotoPage(val) {
      window.open(val);
    },
     search(item, its) {
      if (its) {
        this.$router.push({
          path: "/search",
          query: {
            pid: item.id,
            pname: item.name,
            id: its.id,
            name: its.name,
          },
        });
      } else {
        this.$router.push({
          path: "/search",
          query: {
            pid: item.id,
            pname: item.name,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  background-color: $white;
  padding: 20px;
  margin-top: 12px;
  display: flex;
  height: 528px;
  .scrollbar-hidden{
    height:100%;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 1px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #999;
    }
  }
  & > div:nth-child(1) {
    width: 200px;
    background-color: $white;
    border: 1px solid $borderLight;
    box-sizing: border-box;
    position: relative;
    z-index: 3;
    padding: 12px 0;
    .leimu {
      padding: 8px 12px;
      box-sizing: border-box;
      cursor: pointer;
      & > span:hover {
        color: $primary;
      }
      &:hover {
        // & > span {
        //   color: red;
        // }
        .erjidaohang {
          display: block;
        }
      }
      .erjidaohang {
        display: none;
        position: absolute;
        left: 200px;
        top: 0;
        width: 751px;
        height: 528px;
        background-color: $white;
        border: 1px solid #eee;
        box-sizing: border-box;
        padding: 20px;
        overflow-y: auto;
        & > div {
          & > div:nth-child(1) {
            font-weight: 800;
            color: $primary;
            margin-bottom: 10px;
          }
          & > div:nth-child(2) {
            font-size: 12px;
            padding-left: 30px;
            margin-bottom: 24px;
            box-sizing: border-box;
            & > span {
              margin-right: 22px;
              &:hover{
                color: $primary;
              }
            }
          }
        }
      }
    }
  }
  & > div:nth-child(2) {
    flex: 1;
    overflow: hidden;
    background-color: $white;
    margin: 0 10px;
    .img-wrap{
      position: relative;
      width:100%;
      height:100%;
      overflow:hidden;
      .bannersImg {
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: auto;
        height: 100%;
        cursor: pointer;
      }
    }
  }
}
</style>
