<template>
  <iframe
    v-if="page"
    :id="'ifraHome'+page"
    width="100%"
    :height="height + 'px'"
    :src="`${url}/_apass/lookcustomV3?id=${page}`"
    frameborder="0"
  ></iframe>
</template>

<script>
export default {
  props: {
    page: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      height: 0,
      url: ''
    };
  },
  mounted() {
     this.url =
      process.env.NODE_ENV == "development" ? "https://aa.testfw.cn" : "";
    console.log(this.page);
    window.addEventListener("message", this.setHeight);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.setHeight);
  },
  methods: {
    setHeight() {
      let iframeDom = document.getElementById("ifraHome"+this.page);
      this.height = iframeDom.contentWindow._PostHeight() + 10;
      console.log("ifraHome"+this.page, clinentHeight, this.height)
    },
  },
};
</script>

<style></style>
