<template>
  <div class="searchModel flexBt flexCt">
    <div class="flex flexCt">
      <img
        height="60px"
        @click="gotoPage($store.state.customData.logo.nav_url)"
        :src="$store.state.customData.logo.logo_path"
        alt=""
      />
      <span
        class="navs"
        @click="gotoPage(item.nav_url)"
        v-for="(item, index) in $store.state.customData.nav"
        :key="index"
        >{{ item.name }}</span
      >
    </div>
    <div class="flex flexCt">
      <div class="inputs">
        <input
          :placeholder="$store.state.customData.hot[0].keywords || '请输入'"
          type="text"
          @input="inputText"
        />
        <i class="el-icon-search"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: "",
    };
  },
  methods: {
    gotoPage(val) {
      window.open(val);
    },
    inputText(val) {
      this.input = val.target.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.searchModel {
  height: 90px;
  background-color: $white;
  padding: 0 20px;
  .navs {
    margin: 0 20px;
    font-weight: 800;
    color: $textPrimary;
  }
  .inputs {
    height: 30px;
    width: 200px;
    border: 1px solid $black;
    display: flex;
    border-radius: 15px;
    align-items: center;
    input {
      height: 22px;
      border: none;
      padding-left: 3px;
      margin-left: 5px;
    }
  }
}
</style>
