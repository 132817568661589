<template>
  <div>
    <div class="content" v-if="looktype == 1">
      <div>
        <div class="scrollbar-hidden">
          <div class="leimu" v-for="(item, index) in $store.state.customData.category" :key="index">
            <span @click="search(its)" v-for="(its, ind) in item" :key="its.id">{{ its.name_as }}<span v-if="ind != item.length - 1">/</span>
            </span>
            <div class="erjidaohang">
              <div v-for="its in item" :key="its.id">
                <div class="showTitle" @click="search(its)">
                  {{ its.name_as }}/{{ its.name }}
                </div>
                <div>
                  <span @click="search(its, it)" v-for="it in its.children" :key="it.id">{{ it.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <el-carousel height="528px">
          <el-carousel-item v-for="(item, index) in $store.state.customData.banner" :key="index">
            <div class="img-wrap">
              <img @click="gotoPage(item.nav_url)" class="bannersImg" :src="item.img_path" alt="" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div>
        <div>
          {{ $store.state.customData.promote.welcome }}
        </div>
        <div>
          <div @click="gotoPage(item.url)" v-for="(item, index) in $store.state.customData.promote.button.btn" :key="index" :style="
            index == 0
              ? 'background-color:' +
                $store.state.customData.promote.button.bg_color +
                ';border-color:' +
                $store.state.customData.promote.button.bg_color +
                ';color:#fff'
              : 'border-color:' +
                $store.state.customData.promote.button.bg_color +
                ';color:' +
                $store.state.customData.promote.button.bg_color +
                ';'
          ">
            {{ item.title }}
          </div>
        </div>
        <div>
          <div v-for="(item, index) in $store.state.customData.promote.nav.icon" :key="index" @click="gotoPage(item.url)">
            <img v-if="(item.code.code || '').startsWith('http')" :src="item.code.code" alt="" width="36px" height="36px" />
            <i v-else :style="`color:${item.code.color};font-size:36px`" :class="item.code.code"></i>
            <span style="margin-top: 5px">{{ item.title }}</span>
          </div>
        </div>
        <div>
          <el-carousel height="144px" indicator-position="none">
            <el-carousel-item v-for="(item, index) in $store.state.customData.promote.ad" :key="index">
              <div class="img-wrap">
                <img @click="gotoPage(item.url)" class="bannersImg" width="100%" :src="item.img_path" alt="" />
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div v-if="looktype == 2">
      <div>
        <div class="mobile-nav">
          <div v-for="(item, index) in navLen" :key="index" @click="toList(item)">
            <div class="img-box"><img :src="item.icon_path && item.icon_path.code"></div>
            <p class="ellipsisText">{{item.name_as}}</p>
          </div>
          <div @click="open">
            <div style="" class="bg-img" :style="{background: `url(${$store.state.customData.logo.logo_path})`, 'background-repeat': 'no-repeat',
      'background-size': 'contain',
      'background-position': 'center'}">
              <!-- <img :src="$store.state.customData.logo.logo_path"  style="width: 100%;heiht: auto!important;max-height: 30px"> -->
              <!-- <img :src="$store.state.customData.logo.logo_path" style="width: 100%;heiht: auto!important;max-height: 32px"> -->
              
            </div>
            <p>更多分类</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [],
      tag: false,
      num: 9
    };
  },
  computed:{
    navLen(){
      if(this.navList.length != 0){
        if(!this.tag){
          return this.navList.slice(0,9)
        }else{
          return this.navList
        }
      }
    }
  },
  created() {
    if (
      this.$store.state.customData &&
      this.$store.state.customData.category.length != 0
    ) {
      console.log(
        this.$store.state.customData.category,
        "this.$store.state.customData.category"
      );

      this.$store.state.customData.category.forEach((item) => {
        item.forEach((citem) => {
          if (!this.navList.some((i) => i.id === citem.id)) {
            this.navList.push(citem);
          }
        });
      });
    }
  },
  methods: {
    open(){
      this.tag = !this.tag;
    },
    gotoPage(val) {
      window.open(val);
    },
    search(item, its) {
      if (its) {
        this.$router.push({
          path: "/search",
          query: {
            pid: item.id,
            pname: item.name,
            id: its.id,
            name: its.name,
          },
        });
      } else {
        this.$router.push({
          path: "/search",
          query: {
            pid: item.id,
            pname: item.name,
          },
        });
      }
    },
    toList(item){
      this.$router.push({
        path: '/shopStore/categoryList',
        query:{
          category_id: item.category_id,
          category_name: item.name_as
          // id: item.id
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  margin-top: 12px;
  display: flex;
  height: 528px;
  .scrollbar-hidden {
    height: 100%;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 1px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #999;
    }
  }
  & > div:nth-child(1) {
    width: 200px;
    background-color: $white;
    box-sizing: border-box;
    position: relative;
    z-index: 3;
    padding: 12px 0;
    .leimu {
      padding: 8px 12px;
      box-sizing: border-box;
      cursor: pointer;
      & > span:hover {
        color: $primary;
      }
      &:hover {
        // & > span {
        //   color: $primary;
        // }
        .erjidaohang {
          display: block;
        }
      }
      .erjidaohang {
        display: none;
        position: absolute;
        left: 200px;
        top: 0;
        width: 751px;
        height: 528px;
        background-color: $white;
        border: 1px solid #eee;
        box-sizing: border-box;
        padding: 20px;
        overflow-y: auto;
        & > div {
          & > div:nth-child(1) {
            font-weight: 800;
            color: $primary;
            margin-bottom: 10px;
          }
          & > div:nth-child(2) {
            font-size: 12px;
            padding-left: 30px;
            margin-bottom: 24px;
            box-sizing: border-box;
            & > span {
              margin-right: 22px;
              &:hover {
                color: $primary;
              }
            }
          }
        }
      }
    }
  }
  & > div:nth-child(2) {
    flex: 1;
    overflow: hidden;
    background-color: $white;
    margin: 0 10px;

    .img-wrap {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      .bannersImg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: 100%;
        cursor: pointer;
      }
    }
  }
  & > div:nth-child(3) {
    width: 240px;
    background-color: $white;
    padding: 12px;
    box-sizing: border-box;
    .img-wrap {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      .bannersImg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: 100%;
        cursor: pointer;
      }
    }
    & > div:nth-child(1) {
      text-align: center;
      margin-bottom: 15px;
    }
    & > div:nth-child(2) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      & > div {
        width: 45%;
        height: 28px;
        border-radius: 8px;
        line-height: 28px;
        text-align: center;
        border: 1px solid #ccc;
        cursor: pointer;
      }
    }
    & > div:nth-child(3) {
      padding-top: 20px;
      border-top: 1px solid $borderLighter;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      & > div {
        width: 33.33%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-bottom: 16px;
      }
    }
  }
}
.mobile-nav {
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  background: #fff;
  margin-top: 8px;
  padding: 20px 10px 6px 10px;
  box-sizing: border-box;
  border-radius: 10px;
  & > div {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    & > p {
      width: 100%;
      font-size: 0.9em;
      text-align: center;
      padding-top: 8px;
    }
    .img-box {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.bg-img{
  width: 100%;
  height: 32px; display: flex;align-items: center;  
}
</style>
